import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {environment} from "@environments/environment";

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.sass']
})
export class ModalInfoComponent implements OnInit {

  public versionClient: string = environment.VERSION_CLIENT;
  public versionServer: string = environment.VERSION_SERVER;
  constructor(
    public dialogRef: MatDialogRef<ModalInfoComponent>,
  ) { }

  ngOnInit(): void {
  }

}
