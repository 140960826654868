import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '@environments/environment';
import {Authentication, TokenAuthentication} from "@modules/authentication/interfaces/authentication.interface";
import {Observable, catchError, of, tap, throwError} from "rxjs";
import {TokenService} from "@core/services/token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private baseUrl: string = environment.AUTHPROVIDER_URL;
  private username: string = "";
  private headers: HttpHeaders;
  public options = {};

  constructor(private HttpClient:HttpClient, private tokenService: TokenService) {
    this.headers = new HttpHeaders(this.tokenService.getHeaders());
    this.options = {
      headers: this.headers,
    }
  }

  public setUsername(token: string): void {
    this.username = token;
  }

  public getUsername(): string {
    if(sessionStorage.getItem('username')){
      this.username = sessionStorage.getItem('username') ?? '';
    }
    return this.username;
  }

  public getInfoLogin(authentication: Authentication): Observable<TokenAuthentication> {
    return this.HttpClient.post<TokenAuthentication>(`${this.baseUrl}/login`, authentication)
    .pipe(
      tap(data=>{
        return data;
      })
    )
  }

  public logout(): Observable<Object> {
    sessionStorage.clear();
    return this.HttpClient.delete(`${this.baseUrl}/logout`, this.options)
  }
}
