<h2 mat-dialog-title class="title__dialog">
  ACERCA DE
  <img src="../../../../assets/icons/x_blanca.svg" (click)="dialogRef.close()" class="close-modal" alt="">
</h2>
<div class="content__dialog">
  <mat-dialog-content>
    <img style="display: inline-block; width: 70%; text-align: center;"
         src="assets/images/logo_main_blue_alt.png">
    <p>VERSION DESARROLLO CLIENTE - {{ versionClient }}</p>
    <p>VERSION DESARROLLO SERVIDOR - {{ versionServer }}</p>
  </mat-dialog-content>
</div>
