import { Component } from '@angular/core';
import { LoadApiMap } from '@core/home/services/interface/loadApiMap.interface';
import { MapService } from '@core/home/services/map.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'tracr';
  constructor(private mapService: MapService) {}

  async loadGoogleMapsApi(): Promise<LoadApiMap> {
    const apiKey = environment.GOOGLE_MAPS_API_KEY;

    return new Promise<LoadApiMap>((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=visualization,places`;
      script.async = true;

      script.onload = () => {
        let data: LoadApiMap = {
          isLoad: true,
          error: false,
          message: null
        }
        resolve(data);
      };

      script.onerror = (error) => {
        let data: LoadApiMap = {
          isLoad: false,
          error: true,
          message: Error(`Error cargando la API de Google Maps: ${error}`)
        }
        reject(data);
      };

      document.head.appendChild(script);
    });
  }

  ngOnInit(): void {
    this.loadGoogleMapsApi()
      .then((data) => {
        this.mapService.loadMap.next(data);
      })
      .catch(error => {
        this.mapService.loadMap.next(error);
      });
  }
}
 