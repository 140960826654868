import { environment } from '@environments/environment';
import { RxStompConfig } from '@stomp/rx-stomp';

export const stompConfig: RxStompConfig = {
  // Que servidor?
  brokerURL: environment.WEBSOCKET_URL,


  // ¿Con qué frecuencia debe latir?
  // Intervalo en milisegundos, poner a 0 para desactivar
  // Valor típico 0 - desactivado
  heartbeatIncoming: 0,
  // Valor típico 20000 - cada 20 segundos
  heartbeatOutgoing: 60000,

  // Espera en milisegundos antes de intentar la reconexión automática
  // Poner a 0 para desactivar
  // Valor típico 500 (500 milisegundos)
  reconnectDelay: 200,

  // Registrará los diagnósticos en la consola
  // Puede ser bastante verboso, no se recomienda en producción
  // Omita esta tecla para detener el registro en la consola
  debug: (msg: string): void => {
    //console.log(new Date(), msg);
  },
};
