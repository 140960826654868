export const environment = {
  production: false,
  AUTHPROVIDER_URL : 'https://gca.traces.com.co/dev/auth/api',
  TRACRBACK_URL : 'https://gca.traces.com.co/dev/tracr/api',
  ENTITY: 'GCA',
  CATALOGS_URL: 'https://gca.traces.com.co/dev/standarization/api',
  ROAD_MANAGEMENT_URL: 'https://gca.traces.com.co/dev/roadmanagement/api',
  WEBSOCKET_URL: 'wss://gca.traces.com.co/dev/tracr/updates',
  ACTIVE_DEVICES: true,
  ACTIVE_IPAT_CONFIGURATION: false,
  ACTIVE_INFO_VERSION: false,
  GOOGLE_MAPS_API_KEY: 'AIzaSyB3dtM0sta3lg4NFArfNZWxTqBtnhNjXao',
  TIME_OUT_DIALOG: 2000,
  INITIAL_COORDINATES: {
    latitude: 3.43722,
    longitude: -76.5225
  },
  VERSION_CLIENT: '1.0.0',
  VERSION_SERVER: '2.0.0'
};
