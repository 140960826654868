import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@shared/guards/login.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@modules/authentication/authentication.module').then( a => a.AuthenticationModule ),
  },
  {
    path: '',
    loadChildren: () => import('@modules/main/main.module').then( m => m.MainModule ),
    canActivate: [
      LoginGuard
    ],
    data: {
      requiredGrants: ['LTW']
    },
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
