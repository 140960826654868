<h3 mat-dialog-title class="conteiner-modal-incident">
  {{'INFORMACION'}}
  <img src="../../../../assets/icons/x_blanca.svg" (click)="dialogRef.close()" class="close-modal" alt="">
</h3>
<div class="content__dialog">
  <mat-dialog-content>
    <p>{{ data.message | uppercase }}</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="button__container" (click)="dialogRef.close(true)">
      <button mat-button>{{'aceptar' | uppercase}}</button>
    </div>
  </mat-dialog-actions>
</div>
