import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {catchError, Observable, of} from 'rxjs';
import {TokenService} from "@core/services/token.service";
import {AuthenticationService} from "@core/authentication/services/authentication.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private router:Router,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> |
    boolean | UrlTree {
    const requiredGrants = route.data['requiredGrants'] as Array<string>;
    if (this.tokenService.tokenHasGrant(requiredGrants) && sessionStorage.getItem('tracr')) {
      return true;
    }
    else {
      this.authenticationService.logout()
        .pipe(
          catchError((error) => {
            console.log(error);
            return of(null);
          })
        ).subscribe((data) => {
        this.router.navigate(['/auth/login']).then(r => console.log(r));
      });
      return false;
    }
  }
}
