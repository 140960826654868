import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from '@core/interceptors/auth-interceptor.service';
import {MaterialModule} from "@modules/material/material.module";
import { ModalModule } from '@shared/components/modal/modal.module';
import { RxStompService } from '@core/services/stomp.service';
import { rxStompServiceFactory } from '@core/services/stomp-service-factory';
import { ModalMessageComponent } from '@shared/components/modal-message/modal-message.component';
import { ModalInfoComponent } from './shared/components/modal-info/modal-info.component';
import { NavbarComponent } from '@shared/components/navbar/navbar.component';

@NgModule({
  declarations: [ AppComponent, ModalMessageComponent, ModalInfoComponent ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        MaterialModule
    ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: RxStompService,
      useFactory: rxStompServiceFactory,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
